import '../css/App.css';
import '../css/index.css';
import '../css/styles.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; 
import { db } from "../components/firebaseConfig.js" 

//Navigation Imports
import SignIn from './Login.js'; 
import SignUp from './CreateAccount.js'; 
import Home from './Home.js'; 
import Editor from './Editor.js'
import QuestionBank from './QuestionBank.js'
import Protocols from './Protocols.js'
import DevelopmentPlan from './DevelopmentPlan.js'


function App() {
  return (
      <Routes>
        <Route path='*' element={<SignIn />} />
        <Route exact path="/" element={<SignIn />} />
        <Route exact path="/SignUp" element={<SignUp />} />
        <Route exact path="/Home" element={<Home />} />
        <Route exact path="/Editor" element={<Editor />} />
        <Route exact path="/QuestionBank" element={<QuestionBank />} /> 
        <Route exact path="/Protocols" element={<Protocols /> } />
        <Route exact path="/DevelopmentPlan" element={<DevelopmentPlan /> } />
      </Routes>
  );
}


export default App;
