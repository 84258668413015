import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';

function QuestionList({ db }) {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    // Real-time listener for the 'Questions' collection
    const unsubscribe = onSnapshot(collection(db, 'Questions'), (snapshot) => {
      setQuestions(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, [db]);

  return (
    <div>
      <ul>
        {questions.map(question => (
          <li key={question.id}>
            <h2>{question.problem}</h2>
            {question.answers && question.answers.map((answer,index) => (
              <div key={index}>{index + 1}: {answer}</div>
            ))}
            <div>
              <p>Correct Answer: {question.answers && question.answers[question.correctAnswer]}</p></div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default QuestionList;
